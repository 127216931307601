import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";
import store from "@/store";

const firebaseConfig = {
  apiKey: "AIzaSyDlH8JjkRJ4238a3a4UpZOsWIwoFFwVLaw",
  authDomain: "meadow-village-apartments.firebaseapp.com",
  databaseURL: "https://meadow-village-apartments.firebaseio.com",
  projectId: "meadow-village-apartments",
  storageBucket: "meadow-village-apartments.appspot.com",
  messagingSenderId: "644101067696",
  appId: "1:644101067696:web:00fce12816c0cf88b1fc75",
  measurementId: "G-H58VHBEM2Q"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.functions();
var storage = firebase.storage();

auth.onAuthStateChanged(user => {
  if (user) {
    user.getIdTokenResult().then(idTokenResult => {
      user.admin = idTokenResult.claims.admin;
    });

    store.dispatch("updateUser", user);
  } else {
    store.dispatch("updateUser", null);
  }

  store.dispatch("changeAuthReady");
});

export { db, auth, functions, storage };
